.text {
	@include font(base);

	.block--colorSchemeInverted & {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.block--twoContents &--content {
		grid-area: text;
	}


	&--header {
		display: flex;
		flex-direction: column;

		.block--slide & {
			user-select: none;
		}
	}

	.block--vAlignGapAfterContent > .block__container > .block__content > &,
	.block--vAlignGapAroundContent > .block__container > .block__content > & {
		flex-grow: 1;
	}

	.block--textSizeBigger > .block__container > .block__content > &,
	.block--textSizeBigger > .block__container > .block__header > & {
		@include font(baseBigger);
	}

	.block--textSizeSmaller > .block__container > .block__content > &,
	.block--textSizeSmaller > .block__container > .block__header > & {
		@include font(baseSmaller);
	}

	&__category {
		@include font(category);
		order: 1;
		position: relative;

		.text__title ~ &--linked {
			margin-bottom: $base;
		}
	}


	&__categoryLink {
		@include ease(border-bottom-color, $duration2);
		color: currentColor;
		text-decoration: none;
		border-bottom: 1px solid transparent;
		padding-bottom: $baseHalf;

		@include onHover() {
			border-bottom-color: currentColor;
		}

		&:focus {
			border-bottom-color: $colorFocus;
			outline: none;
		}
	}


	&__subtitle {
		@include font(subtitle);
		order: 3;
		position: relative;

		* + & {
			margin-top: $base;
		}

		// .block--textSizeBigger > .block__container > .block__header & {
		// 	@include font(subtitleBigger);
		// }
	}


	&__title {
		@include font(title);
		order: 2;
		position: relative;

		// .block--textSizeBigger > .block__container > .block__header & {
		// 	@include font(titleBigger);
		// }
		.block--stickyTitle & {
			@include font(titleSticky);
			padding: $base;
		}


		.block--slide .block__header & {
			@include font(slideshowTitle);

			.block--productModels & {
				@include font(baseBigger);
				text-transform: none;
				letter-spacing: normal;
				font-weight: bold;
				margin-top: $base3;

				@include media($columnsBreakpoint) {
					display: none;
				}
			}
		}

		.block--titleStyleAlternative > .block__container > .block__header & {
			@include font(titleAlternative);
		}

		.block--titleStyleSmaller > .block__container > .block__header & {
			@include font(titleSmaller);
		}

		.block--productsOverview & {
			margin-right: $base5;
			text-align: left;

			@include media('>tileSizeMedium') {
				text-align: inherit;
				margin-right: unset;
			}
		}
	}


	// an example, eventually would need to be applied also to title, subtitle and category
	// .block--extraLeftMargin & {
	// 	@include media($columnsBreakpoint) {
	// 		padding-left: $base4;
	// 	}
	// }
}
