.noResultsMessage {
	@include font(base);
	@include app-rowLines();
	position: relative;
	background-color: $colorBgBase;
	padding: $base;

	grid-column-end: span 1;

	@include media($columnsBreakpoint) {
		grid-column-end: span 2;
	}
}
