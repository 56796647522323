.menu {
	&__item {
		@include font(menuItem);

		&:not(:last-child) {
			margin-bottom: $base;
		}

		.menu--mainMenu & {
			$sections: (lights, themes, company, services, families, shapes, uses, special);
			$smallSections: (lights, themes, company, services);

			@each $section in $sections {
				&--section#{ucFirst($section)} {
					@if index($smallSections, $section) == null {
						display: none;
					}
					grid-area: $section;

					@include media($mediumBreakpoint) {
						display: block;
					}
				}
			}
		}
	}


	&__items {
		.menu--footerMenu > & {
			display: grid;
			column-gap: $gutter;
			grid-template-columns: repeat(2, 1fr);
			row-gap: $base5;

			@include media($columnsBreakpoint) {
				grid-template-columns: repeat(6, 1fr);
			}
		}

		.menu--mainMenu > & {
			display: grid;
			column-gap: $gutter;
			grid-template-columns: repeat(2, 1fr);
			row-gap: $base5;
			grid-template-areas:
				"lights		themes"
				"company	services";

			@include media($mediumBreakpoint) {
				grid-template-columns: repeat(12, 1fr);
				grid-template-areas:
					"lights		lights		lights		lights		themes		themes		themes		themes		themes		themes		themes		themes"
					"company	company		company		company		services	services	services	services	services	services	services	services"
					"gap		gap			gap			gap			gap			gap			gap			gap			gap			gap			gap			gap"
					"families	families	families	families	families	families	families	families	families	families	families	families"
					"shapes		shapes		shapes		uses		uses		uses		special		special		special		special		special		special";
			}

			@include media($columnsBreakpoint) {
				grid-template-areas:
					"lights		lights		lights		lights		themes		themes		themes		themes		company		company		services	services"
					"gap		gap			gap			gap			gap			gap			gap			gap			gap			gap			gap			gap"
					"families	families	families	families	families	families	shapes		shapes		uses		uses		special		special";
			}

			@include media('>=menuExtraLarge') {
				grid-template-areas:
					"lights		themes		themes		company		services	gap		families	families	families	families	families	families"
					"lights		themes		themes		company		services	gap		shapes		shapes		uses		uses		special		special";
			}


			// used for the separator
			&::before {
				border-top: 1px solid $colorBorder;
				content: '';
				grid-area: gap;
				pointer-events: none;
				position: relative;

				@include media('>=menuExtraLarge') {
					border-left: 1px solid $colorBorder;
					border-top: 0;
					margin-left: 50%;
					width: 1px;
				}
			}
		}

		.menu__sectionTitle + & {
			margin-top: $base2;
		}

		.menu--mainMenu .menu__item--sectionThemes > & {
			@include media($mediumBreakpoint) {
				column-count: 2;
				column-gap: $gutter;
			}

			@include media('>=menuExtraLarge') {
				column-count: 1;
			}
		}

		.menu--mainMenu .menu__item--sectionFamilies > & {
			@include media($mediumBreakpoint) {
				column-count: 4;
				column-gap: $gutter;
			}

			@include media($columnsBreakpoint) {
				column-count: 3;
				column-gap: $gutter;
			}

			@include media('>=menuExtraLarge') {
				column-count: 6;
			}
		}
	}


	&__link {
		color: currentColor;
		text-decoration: none;
	}


	&__sectionTitle {
		@include font(menuSectionTitle);
	}
}
