.footerItem {
	@include font(menuItem);

	& + & {
		margin-top: $base;

		@include media($columnsBreakpoint) {
			margin-top: 0;
		}
	}

	&:nth-child(2n + 3) {
		margin-top: $base * 3.5;

		@include media($columnsBreakpoint) {
			margin-top: 0;
		}
	}

	@include media($columnsBreakpoint) {
		padding: 0 $base $base $base;
	}

	&:nth-child(6n + 1),
	&:nth-child(6n + 2) {
		@include media($columnsBreakpoint) {
			grid-column: 1 / 2;
		}
	}

	&:nth-child(6n + 3),
	&:nth-child(6n + 4) {
		@include media($columnsBreakpoint) {
			grid-column: 2 / 3;
		}
	}

	&:nth-child(6n + 5),
	&:nth-child(6n + 6) {
		@include media($columnsBreakpoint) {
			grid-column: 3 / 4;
		}
	}


	&__link {
		color: inherit;
	}
}
