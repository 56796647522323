.breadcrumbs {
	@include font(base);

	.block--breadcrumbsModeFloating & {
		position: absolute;
		// height: 400px;
		top: 0;
		width: 100%;
	}


	&::after {
		// reserve space so is never overlapping with the floating header
		.block--breadcrumbsModeFloating & {
			content: '';
			display: block;
			height: $headerHeight + $base3;

			@include media($mediumBreakpoint) {
				height: $headerHeight * 2;
			}
		}
	}


	&__item {
		display: inline-block;

		&--current {
			font-weight: bold;
		}


		&--linked::after {
			content: '—';
			display: inline-block;
			padding: 0 $base;
		}
	}


	&__items {
		display: flex;
		flex-wrap: wrap;

		.block--breadcrumbsModeFloating & {
			padding-top: $headerHeight;
			position: sticky;
			width: 100%;
			left: 0;
			top: 0;

			@include media($mediumBreakpoint) {
				padding-top: $headerHeight + $base2;
			}
		}
	}


	&__link {
		color: currentColor;
		pointer-events: auto;
		text-decoration: none;
		border-bottom: 1px solid transparent;


		@include onHover() {
			border-bottom-color: currentColor;
		}
	}
}
