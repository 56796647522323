// useful when you need to set a minimum aspect-ratio, meaning that, in case,
// the height of the element can grow accordingly to the content.
// If you have only one element inside it does not need to be wrapped

// $ratio is defined as width / height, like 16 / 9 or 1 (a square)
@mixin minAspectRatio($ratio, $pseudoElement: 'before', $childSelector: '& > :first-child') {
	$ratioValue: if(type-of($ratio) == number, (100% / $ratio), $ratio);

	display: grid;


	&::#{$pseudoElement} {
		font-size: 0;
		line-height: 0;
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		content: '';
		display: block;
		padding-bottom: $ratioValue;
		z-index: 0;
		visibility: hidden;
		pointer-events: none;

		// this stuff is for safari which is inventing a height sometimes :|
		box-sizing: content-box;
		height: 0;
	}


	#{unquote($childSelector)} {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}
}
