.publicationInfo {
	@include font(category);


	&__additionalInfo {
		@include font(category);

		* + & {
			margin-top: $base2;
		}
	}


	&__author {
		display: inline;

		&:not(:last-child)::after {
			content: ',';
		}
	}


	&__authorLink {
		color: inherit;
		text-decoration: none;

		@include onHover() {
			text-decoration: underline;
		}
	}


	&__authors {
		* + & {
			margin-top: $base2;
		}

		.publicationInfo__authorsLabel + & {
			margin-top: 0;
		}
	}


	&__authorsLabel {
		@include font(category);

		* + & {
			margin-top: $base2;
		}
	}


	&__date {
		* + & {
			margin-top: $base2;
		}
	}
}
