.slideshowTitlesSlide {
	grid-column: 1 / 2;
	grid-row: 1 / 2;

	.slideshow__infoContainer--below.slideshow__infoContainer--navTypeNoText & {
		display: none;

		@include media($mediumBreakpoint) {
			display: block;
		}
	}

	.slideshow--animationTypeFade > .slideshow__slidesContainer > .slideshow__slides & {
		@include ease(opacity visibility, $slideshowFadeDuration);
		@include fadeOut();
		z-index: 1;

		&.js-current {
			@include fadeIn();
			z-index: 2;
		}
	}

	.slideshow--animationTypeSlide > .slideshow__slidesContainer > .slideshow__slides.js-animate & {
		@include ease(transform, $slideshowSlideDuration);
	}

	.slideshow--animationTypeSlide.slideshow--circular > .slideshow__slidesContainer > .slideshow__slides & {
		transform: translateX(calc(100% * var(--slideBalancedPosition)));
	}

	.slideshow--animationTypeSlide:not(.slideshow--circular) > .slideshow__slidesContainer > .slideshow__slides & {
		transform: translateX(calc(100% * var(--slidePosition)));
	}


	&__content {
		@include font(titleAlternative);
		padding: $base2 0;
	}
}
