.searchForm {
	padding: $base2;

	&__content {
		display: flex;
		flex-grow: 1;
	}


	&__input {
		@include font(searchInput);
		border-radius: 0;
		appearance: none;
		background-color: transparent;
		border-color: $colorBorder;
		border-style: solid;
		border-width: 0 0 $buttonBorderSize 0;
		color: inherit;
		height: 100%;
		padding: $base $base2 $base 0;
		width: 100%;

		&:focus {
			outline: 0;
			// border-bottom-color: $colorFocus;
		}


		&::placeholder {
			color: inherit;
			opacity: 0.25;
		}
	}


	&__inputBox {
		flex-grow: 1;
		margin-right: 0;
		position: relative;
	}


	&__searchIcon {
		@include ease(opacity, $duration2);
		pointer-events: none;
		position: absolute;
		right: $base;
		top: 50%;
		line-height: 0;
		transform: translateY(-50%);
		height: 70%;

		.searchForm__input:not(:focus) ~ & {
			opacity: 0.25;
		}


		.searchForm.js-busy & {
			visibility: hidden;
		}


		[data-type="svg"] {
			width: auto;
			height: 100%;
		}
	}


	&__searchBusyIcon {
		pointer-events: none;
		position: absolute;
		right: $base;
		top: 50%;
		line-height: 0;
		transform: translateY(-50%);
		visibility: hidden;
		// width: $base2;
		height: 70%;

		.searchForm.js-busy & {
			visibility: inherit;
		}


		[data-type="svg"] {
			animation-name: spin;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
			animation-duration: 1s;
			width: auto;
			height: 100%;
		}

	}
}
