.programEntry {
	@include app-rowLines();
	background-color: $colorBgBase;
	max-width: 100%;
	margin-left: 1px;
	margin-right: 1px;
	position: relative;


	&__content {
		overflow: hidden;
		padding: $base;
	}


	&__date {
		@include font(category);
	}


	&__description {
		@include font(base);
		margin-top: $base;

		@include media('>=xSmall') {
			min-height: $base * 19;
		}
	}


	&__info {
		@include font(category);
		min-height: $base9;
	}
}
