.contactCard {
	@include ease(opacity visibility, $duration6);
	font-style: normal;

	&.js-duringHidden {
		opacity: 0;
		visibility: hidden;
	}

	&.js-hidden {
		display: none;
	}


	&__address {
		@include font(baseSmaller);
		order: 4;
	}


	&__addressTerm {
		@include visuallyHidden();
	}


	&__addressValue {
		display: inline-block;
		margin: 0;
		text-indent: 0;
	}


	&__category {
		@include font(category);
		order: 1;

		@include media($columnsBreakpoint) {
			min-height: $base5;
		}
	}


	&__contactTerm {
		display: inline-block;
	}


	&__contactValue {
		display: inline-block;
		font-weight: bold;
		margin: 0;
		text-indent: 0;
	}


	&__contacts {
		@include font(baseSmaller);
		order: 5;

		* + & {
			margin-top: $base;
		}
	}


	&__content {
		display: flex;
		flex-direction: column;
	}


	&__figure {
		order: 2;
	}


	&__name {
		@include font(titleSmaller);
		font-weight: bold;
		order: 3;
	}
}
