.field {
	@include font(fieldInput);
	width: 100%;

	&--zipCode {
		max-width: $base * 20;
	}

	&--disabled {
		opacity: $opacityInputDisabled;
	}

	&--bookingCheck {
		width: 50%;

		@include media($columnsBreakpoint) {
			width: 100%;
		}

		.booking__check--out & {
			margin-left: 50%;

			@include media($columnsBreakpoint) {
				margin-left: 0;
			}
		}
	}

	.listFilters & {
		@for $number from 1 through 10 {
			&:nth-child(#{$number}) {
				position: relative;
				z-index: (11 - $number);
			}
		}
	}

	.form__fields & {
		display: grid;
		column-gap: $gutter;
		row-gap: $gutter * 2;
		grid-column-start: span 12;
		grid-template-columns: repeat(12, 1fr);

		@for $number from 1 through 12 {
			&--colSpan#{$number} {
				@include media($columnsBreakpoint) {
					grid-column-start: span $number;
					grid-template-columns: repeat(#{$number}, 1fr);
				}
			}
		}
	}


	&__actions {
		display: flex;
		justify-content: flex-end;

		margin-left: -$base2;
		margin-bottom: -$base2;

		* + & {
			margin-top: $base2;
		}
	}


	&__content {
		position: relative;
		grid-column-start: span 12;
		// grid-column-start: span 12;

		@for $number from 1 through 12 {
			.field--colSpan#{$number} & {

				@include media($columnsBreakpoint) {
					grid-column-start: span $number;
				}

				@for $contentNumber from 1 through $number {
					&--colSpan#{$contentNumber} {
						@include media($columnsBreakpoint) {
							grid-column-start: span $contentNumber;
						}
					}
				}
			}
		}



		.field--bookingCheck & {
			margin-left: 0;
			position: relative;
		}

		.field--group > & {
			margin-left: 0;
		}
	}


	.form__separator + & {
		margin-top: $base4;
	}

	.form__separator + &:not(&--full):not(&--alone) + &:not(&--full):not(&--alone) {
		@include media($columnsBreakpoint) {
			margin-top: $base4;
		}
	}

	// // nested
	// .field__fields > & {
	// 	width: dynamicColumnSize(1, 1, $gutter);
	// }

	// // nested
	// .field__fields--horizontal > & {
	// 	@include media($columnsBreakpoint) {
	// 		display: inline-block;
	// 		width: auto;
	// 		margin-top: 0;
	// 	}

	// 	.trackers & {
	// 		margin-left: 0;
	// 		width: staticColumnSize(1, 2, $gutter);
	// 	}
	// }

	// .field__fields--grid > & {
	// 	@include media($columnsBreakpoint) {
	// 		width: dynamicColumnSize(1, 3, $gutter);
	// 	}

	// 	&--double {
	// 		@include media($columnsBreakpoint) {
	// 			width: dynamicColumnSize(2, 3, $gutter);
	// 		}
	// 	}
	// }

	.field--checkboxes &,
	.field--radios & {
		margin-top: 0;
	}


	&__description {
		&:not(&--extended) {
			@include font(fieldDescription);
			margin-top: $baseHalf;
		}

		.field--password &,
		.field--select &,
		.field--text &,
		.field--textarea & {
			margin-top: $baseHalf;
		}

		.field--checkbox &,
		.field--radio & {
			margin-left: $base3;
		}
	}

	&__error {
		margin-top: $baseHalf;
	}


	&__errors {
		@include font(fieldError);
		color: $colorError;
	}


	&__fields {
		@include grid(12, $gutter, $gutter * 2);
		position: relative;

		.field--checkboxes &,
		.field--radios & {
			@include dynamicColumns($hSpace: $gutter, $vSpace: $base3, $hSpacePos: left, $vSpacePos: top);
			position: relative;
			margin-top: 0;
		}

		&--horizontal {
			@include dynamicColumns($hSpace: $gutter, $vSpace: $base3, $hSpacePos: left, $vSpacePos: top);
			position: relative;

			@include media($columnsBreakpoint) {
				font-size: 0;
				display: block;
				margin-top: 0;
			}

			.trackers & {
				@include staticColumns();
				position: relative;
				margin-left: 0;
			}
		}
	}


	&__info {
		color: inherit;
		display: block;

		&--strong {
			color: $colorBase;
		}

		.field--radio &,
		.field--checkbox & {
			margin-left: $base3;
		}

		.field__label + & {
			margin-top: -$base;

			@include media($columnsBreakpoint) {
				margin-top: 0;
			}
		}

		.field__inputWrapper--extended & {
			@include media($columnsBreakpoint) {
				padding: $inputPaddingVertical 0;
				margin-left: 0;
				width: dynamicColumnSize(1, 6, $gutter);
			}

			&--compact {
				@include media($columnsBreakpoint) {
					width: dynamicColumnSize(2, 12, $gutter);
				}
			}

			&--right {
				@include media($columnsBreakpoint) {
					text-align: right;
				}
			}

			&--first {
				color: $colorBase;
				flex-grow: 1;
			}
		}
	}


	&__input {
		@include font(fieldInput);
		background-color: transparent;
		color: inherit;

		&:focus {
			outline: none;
			border-color: $colorFocus;
		}

		&--bookingCheck {
			height: 100%;
			left: 0;
			opacity: 0;
			pointer-events: all;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
			-webkit-appearance: none;

			@include media($enoughHeightBreakpoint) {
				@include visuallyHidden();
				pointer-events: none;
				visibility: hidden;
			}
		}

		&--password,
		&--text {
			border: $inputBorderSize solid $colorInputBorder;
			padding: $inputPaddingVertical $inputPaddingHorizontal;
			width: 100%;

			.field--invalid & {
				border-color: $colorError;
			}

			// .field__label--radioText & {
			// 	padding: $inputNestedPadding $inputPaddingHorizontal;
			// }

			.field--search & {
				width: auto;
				flex-grow: 1;
				flex-shrink: 1;
			}

			.field--bookingAmount & {
				background-color: transparent;
				border-radius: 0;
				text-align: center;
				// -moz-appearance: textfield;
				-webkit-appearance: textfield;


				::placeholder {
					text-align: center;
				}


				&::-webkit-inner-spin-button,
				&::-webkit-outer-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}

		&--textarea {
			// background-color: $colorBgBase;
			border: $inputBorderSize solid $colorInputBorder;
			min-height: $base2 + getFontSize(base) * getLineHeight(base) * 4;
			padding: $base;
			width: 100%;

			.field--invalid & {
				border-color: $colorError;
			}
		}

		&--select {
			background-color: transparent;
			border: $inputBorderSize solid $colorInputBorder;
			// needed because on OSX to reset the appearance is not enough
			border-radius: 0;
			min-width: $buttonMinWidth;
			padding: $inputPaddingVertical $base2 $inputPaddingVertical $inputPaddingHorizontal;
			position: relative;
			width: 100%;
			z-index: 2;
			appearance: none;

			&::-ms-expand {
				display: none;
			}

			.field--invalid & {
				border-color: $colorError;
			}
		}

		&--checkbox,
		&--radio {
			@include visuallyHidden();
		}
	}


	&__inputWrapper {
		position: relative;

		.field--bookingCheck & {
			height: 100%;
			left: 0;
			margin: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
		}

		.field--select & {
			background-color: transparent;
			// background-color: $colorBgBase;
		}

		.field__inputOptions & {
			padding: 0 $base;
			border-bottom: 1px solid $colorBorder;


			@include media($columnsBreakpoint) {
				padding: 0;
				border-bottom: 0;
			}
		}

		&--extended {
			@include media($columnsBreakpoint) {
				@include dynamicColumns($hSpace: $gutter, $hSpacePos: right);
			}
		}

		.field--horizontal & {
			@include media($columnsBreakpoint) {
				display: inline-block;
			}
		}

		.field--search & {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
		}

		.field--radioText & {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			position: relative;
		}
	}


	&__label {
		@mixin replaceInput($circle: true) {
			padding-left: $base3;

			&::before {
				border: $inputBorderSize solid $colorInputBorder;
				@if $circle {
					border-radius: 50%;
				}
				content: '';
				height: $radioOuterSize;
				left: 0;
				position: absolute;
				top: $inputPaddingVertical + (getLineHeight(fieldLabel) * getFontSize(fieldLabel) / 2);
				margin-top: -($radioOuterSize / 2);
				width: $radioOuterSize;
			}

			&::after {
				@include ease(opacity, $duration2);
				background-color: currentColor;
				@if $circle {
					border-radius: 50%;
				}
				content: '';
				height: $radioOuterSize; //$radioInnerSize;
				left: 0;
				opacity: 0;
				position: absolute;
				top: $inputPaddingVertical + (getLineHeight(fieldLabel) * getFontSize(fieldLabel) / 2);
				margin-top: -($radioOuterSize / 2);
				transform: scale($radioInnerScale);
				width: $radioOuterSize;//$radioInnerSize;
			}

			input:focus ~ & {
				outline-color: currentColor;
				outline-width: $inputBorderSize;
				outline-style: solid;
			}
		}

		@include font(fieldLabel);
		border-color: transparent;
		border-style: solid;
		border-width: $inputBorderSize 0;
		color: inherit;
		display: block;
		padding: $inputPaddingVertical 0;
		position: relative;

		&--extraLabel {
			font-weight: bold;
		}

		&--strong {
			color: $colorBase;
		}

		&--important {
			font-weight: bold;
		}

		&--toggler {
			padding-left: $base;
			padding-right: $base;
			border-bottom: 1px solid $colorBorder;

			@include media($columnsBreakpoint) {
				padding-left: 0;
				padding-right: 0;
				border-bottom: 0;
			}
		}


		.field--bookingAmount & {
			@include visuallyHidden();
		}

		.field--bookingCheck & {
			border-color: currentColor;
			border-style: solid;
			border-width: $buttonBorderSize;
			display: block;
			margin: 0;
			padding: $inputPaddingVertical 0;
			pointer-events: none;
			position: relative;
			text-align: center;
			width: 100%;
			z-index: 2;

			@include media($enoughHeightBreakpoint) {
				border-bottom-width: 0;
			}

			.booking__check.js-active & {
				@include media($enoughHeightBreakpoint) {
					background-color: $colorBgBase;
				}

				@include media($columnsBreakpoint) {
					background-color: transparent;
				}
			}

			@include media($enoughHeightBreakpoint) {
				pointer-events: all;
			}

			@include media($columnsBreakpoint) {
				pointer-events: none;
			}

			.booking__check--out & {
				border-left-width: 0;

				@include media($columnsBreakpoint) {
					border-left-width: $buttonBorderSize;
				}
			}
		}

		.field--group > .field__content > & {
			margin-left: 0;
		}

		.field--password,
		.field--text & {
			display: block;
		}

		&--checkbox {
			input[type="checkbox"] ~ & {
				@include replaceInput($circle: false);
			}

			input[type="checkbox"]:checked ~ &::after {
				opacity: 1;
			}

			// input[type="checkbox"][disabled="disabled"] + &::before {
			// 	display: none;
			// }

			input[disabled] ~ &::before {
				background-color: $colorInputBorder;
			}

			input:focus ~ &::before {
				outline-color: currentColor;
				outline-offset: 1px;
				outline-width: $inputBorderSize;
				outline-style: dotted;
			}
		}

		&--radio {
			input[type="radio"] ~ & {
				@include replaceInput($circle: true);
			}

			input[type="radio"]:checked ~ &::after {
				opacity: 1;
			}

			input[disabled] ~ &::before {
				background-color: $colorInputBorder;
			}

			input:focus ~ &::before {
				outline-color: currentColor;
				outline-offset: 1px;
				outline-width: $inputBorderSize;
				outline-style: dotted;
			}

			.field--radioText & {
				// bottom: 0;
				// flex-grow: 0;
				// left: 0;// $base3;
				// padding-right: 0;
				// padding-left: 0;
				// position: absolute;
				// right: 0;
				// top: 0;
			}

			.field__inputWrapper--extended & {
				@include media($columnsBreakpoint) {
					flex-grow: 1;
				}
			}
		}

		&--radioText {
			padding: $labelNestedPadding 0;
			flex-grow: 1;
		}

		.field__inputWrapper--extended & {
			@include media($columnsBreakpoint) {
				width: dynamicColumnSize(6, 12, $gutter, -$base3);
			}
		}
	}


	&__inputOptions {
		@include ease(opacity visibility, $duration4);
		background-color: $colorBgBase;
		border-bottom: 1px solid $colorCustomSelectOptionBorder;
		left: 0;
		position: absolute;
		// top: 0;
		width: 100%;
		display: none;
		opacity: 0;
		visibility: hidden;

		&.js-beforeOpened {
			display: block;
		}

		&.js-duringOpened {
			opacity: 1;
			visibility: inherit;
		}

		@include media($columnsBreakpoint) {
			background-color: transparent;
			border-bottom: 0;
			left: auto;
			position: static;
			width: auto;
			display: block;
			opacity: 1;
			visibility: inherit;
		}
	}


	&__labelIcon {
		@include ease(transform, $duration4);
		position: relative;
		width: $base2;
		height: $base2;

		.field__label--toggler.js-toggled & {
			transform: rotate(180deg);
		}

		@include media($columnsBreakpoint) {
			display: none;
		}
	}


	&__labelValue {
		.field--required > .field__content > .field__label &::after,
		.field--required > .field__content > .field__inputWrapper > .field__label &::after {
			content: '*';
		}

		.field--radio &::before {
			content: '';
			position: absolute;
			height: 100%;
			width: $base3;
			left: -$base3;
			top: 0;
		}


		.field__label--toggler & {
			display: flex;
			justify-content: space-between;
		}
	}


	&__selectIcon {
		color: inherit;
		position: absolute;
		right: $baseHalf;
		top: 50%;
		height: $base;
		width: $base;
		text-align: center;
		transform: translateY(-50%) rotate(90deg);
		z-index: 1;


		[data-type="svg"] {
			width: auto;
			height: 100%;
		}
	}

	&__text {
		&--info {
			margin-top: $base3;
			margin-left: 0;
			margin-bottom: $base3;
		}
	}


	&__link {
		display: block;
		font-weight: bold;
		padding-bottom: $base1;
		text-decoration: none;

		@include onHover {
			color: $colorBase;
		}
	}
}
