.abstract {
	@include app-rowLines();
	background-color: $colorBgBase;
	max-width: 100%;
	margin-left: 1px;
	margin-right: 1px;
	position: relative;


	&__content {
		padding: $base;
	}


	&__authors {
		@include font(category);
	}


	&__title {
		@include font(title);
		min-height: $base * 16;

		* + & {
			margin-top: $base;
		}
	}


	&__description {
		@include font(base);
		margin-top: $base;

		@include media($columnsBreakpoint) {
			min-height: $base * 20;
		}
	}
}
