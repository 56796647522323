.trackers {
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	left: 0;
	position: fixed;
	right: 0;
	min-height: 100vh;
	z-index: 20;
	pointer-events: none;

	&.js-fixed {
		position: fixed;
	}

	&.js-beforeActive {
		position: relative;
	}

	&.js-hidden {
		display: none;
	}


	&::before {
		@include ease(opacity, visibility, $duration4);
		@include fadeOut();
		background-color: $colorBgModalCover;
		content: '';
		bottom: 0;
		left: 0;
		pointer-events: auto;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 0;
	}

	&.js-modal::before,
	&.js-beforeActive::before {
		@include fadeIn();
	}



	&__actions {
		@include staticColumns($base);

		@include media($columnsBreakpoint) {
			margin-left: staticColumnPush(1, 12, $gutter);
			width: staticColumnSize(4, 12, $gutter);
		}
	}


	&__container {
		@include ease(height, $duration4);
		border-top: 1px solid currentColor;
		background-color: $colorBgInverted;
		color: $colorInverted;
		overflow: hidden;
		padding: $base2 0;
		pointer-events: auto;
		position: relative;
		flex-grow: 0;
		flex-shrink: 0;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}


	&__details {
		@include app-container();
		@include ease(opacity visibility, $duration2);
		left: 0;
		// padding: $base 0 0 $base;
		padding-top: $base3;
		position: relative;
		right: 0;
		top: 0;

		&.js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}
	}


	&__detailsActions {
		@include media($columnsBreakpoint) {
			margin-left: staticColumnPush(8, 12, $gutter);
			width: staticColumnSize(4, 12, $gutter);
		}
	}


	&__group {
		@include ease(height, $duration4);
		position: relative;
		overflow: hidden;

		& + & {
			margin-top: $base4;
		}
	}


	&__groupActions {
		@include media($columnsBreakpoint) {
			margin-left: staticColumnPush(1, 12, $gutter);
			width: staticColumnSize(4, 12, $gutter);
		}
	}


	&__groupDetails {
		@include ease(opacity visibility, $duration2);
		left: 0;
		position: relative;
		right: 0;
		bottom: 0;

		&.js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}
	}


	&__groupInfo {
		@include media($columnsBreakpoint) {
			width: staticColumnSize(7, 12, $gutter);
		}
	}


	&__groupSummary {
		@include media($columnsBreakpoint) {
			@include staticColumns();
			align-items: center;
		}
	}


	&__info {
		@include font(baseSmaller);

		@include media($columnsBreakpoint) {
			width: staticColumnSize(7, 12, $gutter);
		}
	}


	&__summary {
		@include app-container();
		@include media($columnsBreakpoint) {
			@include staticColumns();
			align-items: center;
		}
	}


	&__tracker {
		@include media($columnsBreakpoint) {
			@include staticColumns();
		}

		& + & {
			border-top: 1px solid currentColor;
			margin-top: $base;
			padding-top: $base;
		}
	}


	&__trackers {
		padding-top: $base2;
	}


	&__trackerActions {
		@include media($columnsBreakpoint) {
			margin-left: staticColumnPush(1, 12, $gutter);
			width: staticColumnSize(4, 12, $gutter);
		}
	}


	&__trackerInfo {
		@include media($columnsBreakpoint) {
			width: staticColumnSize(7, 12, $gutter);
		}
	}
}
