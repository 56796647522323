@mixin app-rowLines($parentClass: '&', $pseudoElement: 'after') {
	$selector: $parentClass + '::' + $pseudoElement;

	#{$selector} {
		bottom: 0;
		top: 0;
		content: '';
		left: 50%;
		margin-left: -100vw;
		width: 200vw;
		position: absolute;
		outline: 1px solid $colorBorder;
		pointer-events: none;
	}
}
