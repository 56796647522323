.footer {
	background-color: $colorBgInverted;
	color: $colorInverted;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&__bottomMenu {
		padding: $base4 $base;

		@include media($columnsBreakpoint) {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			gap: 1px;
			padding: $base6 $base;
		}
	}


	&__bottomMenuLink {
		display: block;
		max-width: 100%;

		@include media($columnsBreakpoint) {
			padding: 0 $base;
		}

		&--uni {
			@include media($columnsBreakpoint) {
				grid-column-start: 4;
			}
		}

		&--dfg {
			margin-top: $base4;

			@include media($columnsBreakpoint) {
				grid-column: 5 / 7;
				margin-top: 0;
			}
		}


		&--uni [data-type="svg"] {
			width: $base * 12;
		}


		&--dfg [data-type="svg"] {
			width: $base * 24;
		}
	}


	&__items {
		@include media($columnsBreakpoint) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-auto-flow: row dense;
		}


	}

	&__linkMenu {
		margin-top: $base7;

		@include media($columnsBreakpoint) {
			margin-top: 0;
			grid-column-start: span 3;
		}

	}


	&__logo {
		@include media($columnsBreakpoint) {
			grid-column-start: span 3;
			justify-items: start;
		}
	}


	&__logoLink {
		display: block;
		max-width: $base * 34;


		[data-type="svg"] {
			width: 100%;
		}
	}


	&__topMenu {
		border-bottom: 1px solid $colorInverted;
		padding: $base3 $base $base6 $base;

		@include media($columnsBreakpoint) {
			display: grid;
			column-gap: 1px;
			grid-template-columns: repeat(6, 1fr);
			padding: $base5 $base;
		}
	}
}
