$colorBase: #000000 !default;
$colorBgBase: #E5E5E5 !default;
$colorInverted: #FFFFFF !default;
$colorBgInverted: $colorBase !default;
$colorAlternative: $colorBase !default;
$colorBgAlternative: #FFFFFF !default;

$colorFocus: #3E3EFF;
$colorBgButtonAlternativeHover: #CCCCCC;
$colorBgButtonAlternativeInvertedHover: #333333;

$colorBgSubmenu: #191919;
$colorBorder: $colorBase;

$colorBgHeaderOverContent: (
	default: $colorBgBase,
	alternative: $colorBgBase,
	inverted: $colorBgInverted
);


$colorBgModal:$colorBgBase !default;


$colorInputBorder: $colorBorder !default;
$colorError: #FF0000 !default;

$colorBgModalCover: transparentize($colorBgInverted, 0.4) !default;


$colorCustomSelectOptionBorder: $colorBgAlternative;
$colorCustomSelectOptionBorderAlternative: $colorBorder;

$colorBgCustomSelectReset: $colorBgAlternative;
$colorCustomSelectReset: #959595;

$colorFilterTag: $colorBase;
$colorBgFilterTag: $colorBgAlternative;
$colorFilterTagAlternative: $colorInverted;
$colorBgFilterTagAlternative: $colorBorder;


$colorProductsOverviewGridBorder: $colorBgAlternative;  // #606060;
$colorProductsOverviewInactiveMenu: #777777;

$colorScrollBarBgTrack: transparent;
$colorScrollBarBgHandle: #939393;

$colorSlideshowDirection: $colorBase;
$colorBgSlideshowDirection: rgba(0, 0, 0, 0.2);

$colorSlideshowDirectionInverted: $colorInverted;
$colorBgSlideshowDirectionInverted: rgba(255, 255, 255, 0.2);


$colorBgOverlayLinkGridItemDefault: rgba(0, 0, 0, 0.05);
$colorBgOverlayLinkGridItemAlternative: rgba(0, 0, 0, 0.05);
$colorBgOverlayLinkGridItemInverted: rgba(255, 255, 255, 0.1);

$colorListGridLinkBorder: $colorBorder;

$colorSchemes: (
	default: (
		background-color: $colorBgBase,
		color: $colorBase,
	),

	inverted: (
		background-color: $colorBgInverted,
		color: $colorInverted,
	),

	alternative: (
		background-color: $colorBgAlternative,
		color: $colorAlternative,
	),

	defaultText: (
		background-color: transparent,
		color: $colorBase,
	),

	invertedText: (
		background-color: transparent,
		color: $colorInverted,
	)
);
