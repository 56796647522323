// the regular version is directly requested in the <head> in order to prefetch the file
@font-face {
	font-family: 'Nantes';
	src:
		url('../fonts/NantesWeb-RegularItalic.woff2') format('woff2'),
		url('../fonts/NantesWeb-RegularItalic.woff') format('woff');
	font-style: italic;
	font-weight: normal;
}

@font-face {
	font-family: 'GtAmerica';
	src:
		url('../fonts/GT-America-Mono-Regular.woff2') format('woff2'),
		url('../fonts/GT-America-Mono-Regular.woff') format('woff');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'GtAmerica';
	src:
		url('../fonts/GT-America-Mono-Bold.woff2') format('woff2'),
		url('../fonts/GT-America-Mono-Bold.woff') format('woff');
	font-style: normal;
	font-weight: bold;
}
