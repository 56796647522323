.personInfo {
	display: grid;
	grid-template-columns: 1fr 2fr;
	column-gap: $base2;


	&__category {
		@include font(category);

		& + & {
			margin-top: $base;
		}
	}


	&__description {
		@include font(base);

		* + & {
			margin-top: $base2;
		}
	}


	&__figure {
		position: relative;
		width: 100%;

		&--placeholder {
			@include minAspectRatio($imagePlaceholderRatio);
			align-self: start;
		}
	}


	&__figurePlaceholder {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: 1px solid $colorBorder;
		overflow: hidden;


		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 200%;
			border-bottom: 1px solid $colorBorder;
			transform-origin: 0 0;
			transform: rotate($imagePlaceholderLinesAngle);
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			top: auto;
			width: 200%;
			border-bottom: 1px solid $colorBorder;
			transform-origin: 0 0;
			transform: rotate(-$imagePlaceholderLinesAngle);
		}
	}


	&__workgroups {
		@include font(category);

		* + & {
			margin-top: $base;
		}
	}


	&__workgroupsTitle {
		@include font(category);
		font-weight: normal;
	}


	&__workgroupLink {
		@include app-link();
		// color: inherit;
	}
}
