.gallery {
	// &:not(&--layoutTypeJustified) {
	@include dynamicColumns($gutter, $gutter);
	// }

	// &--layoutJustified {
	// 	display: flex;
	// 	flex-wrap: wrap;
	// 	margin-left: -$gutter;
	// 	margin-top: -$gutter;
	// }


	&::after {
		--ratio: 2;
		--row-height: 30rem;
		content: '';
		flex-basis: calc(var(--ratio) * var(--row-height));
		flex-grow: 1000000;
	}


	&__item {
		padding-left: $blockHorizontalPaddingDefaultSmall;
		padding-right: $blockHorizontalPaddingDefaultSmall;
		width: dynamicColumnSize(1, 1, $gutter);

		@include media($columnsBreakpoint) {
			padding-left: 0;
			padding-right: 0;
		}

		.gallery--layoutTypeJustified & {
			// @include ifSupportCustomProperties(false) {
			// 	width: 100%;
			// }

			// it implies to have vars --w and --h set inline in the item
			@include ifSupportCustomProperties() {
				@include media($columnsBreakpoint) {
					--ratio: calc(var(--w) / var(--h));
					--rowMinHeight: #{$galleryJustifiedItemMinHeight};
					flex-basis: calc(var(--ratio) * var(--rowMinHeight));
					flex-grow: calc(var(--ratio) * 100);
					width: auto;
				}
			}
		}

		.gallery:not(.gallery--layoutTypeJustified) & {

			@include media($columnsBreakpoint) {
				@include dynamicColumnsModifiers($gutter);
			}
		}
	}
}


// $galleryJustifiedItemMinHeight: $base * 30;
// $galleryColumnsNumber: 2;
// $galleryColumnsImageRatio: 3 / 2;


// .jg > a, .jg::after {
// 	--ratio: calc(var(--w) / var(--h));
// 	--row-height: 9rem;
// 	flex-basis: calc(var(--ratio) * var(--row-height));
//   }

//   .jg > a {
// 	margin: 0.25rem;
// 	flex-grow: calc(var(--ratio) * 100);
//   }

//   .jg::after {
// 	--w: 2;
// 	--h: 1;
// 	content: '';
// 	flex-grow: 1000000;
//   }

//   .jg > a > img {
// 	display: block;
// 	width: 100%;
//   }

// .container {
// 	max-width: 1024px;
// 	margin: 0 auto;
// 	display: flex;
// 	flex-wrap: wrap;
//   }
//   .img-holder {
// 	flex-grow:1;
// 	padding: .2rem;
//   }

//   .img-holder img {
// 	display: block;
// 	min-width: 100%;
// 	height: 16rem;
// 	object-fit: cover;
//   }
