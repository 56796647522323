.listTile {
	@mixin bigEnoughLayout() {
		@include media($tileSizePartialBreakpoint) {
			.filterableList--productsOverview.js-largeTiles & {
				@content;
			}
		}

		@include media($tileSizeFullBreakpoint) {
			@content;
		}
	}

	@include aspectRatio($tileRatio);
	@include ease(opacity visibility, $duration6);
	background-color: $colorBgBase;
	color: $colorBase;
	position: relative;
	outline: 1px solid $colorBorder;

	&--family {
		background-color: $colorBgInverted;
		color: $colorInverted;
		z-index: 10;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&--product {
		display: none;

		@include media('>=tileSizeSmall') {
			display: block;
		}
	}

	// &.js-beforeHidden {
	// }

	&.js-duringHidden {
		opacity: 0;
		visibility: hidden;
	}

	&.js-hidden {
		display: none;
	}


	&--family::after {
		border-bottom: $base solid transparent;
		border-left: $base solid $colorBgInverted;
		border-top: $base solid transparent;
		content: '';
		display: none;
		height: 0;
		left: 100%;
		margin-left: -1px;
		position: absolute;
		top: $base4;
		width: 0;
	}

	&--family.js-opened::after {
		@include media('>=tileSizeSmall') {
			display: block;
		}
	}


	&__bg {
		@include ease(opacity, $duration4);
		height: 100%;
		left: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 0;

		.listTile--product:hover & {
			opacity: 0.1;
		}
	}


	&__category {
		margin-top: $baseHalf;
		color: currentColor;
		text-align: center;
	}


	&__content {
		height: 100%;
		left: 0;
		overflow: hidden;
		padding: $base2 ($base * 1.5) ($base * 1.5) ($base * 1.5);
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;

		display: flex;
		flex-direction: column;
	}


	&__menu {
		@include fadeOut();
		flex-grow: 1;
		padding-top: $base4;
		position: relative;
		z-index: 3;

		.filterableList--productsOverview.js-ready & {
			@include ease(opacity visibility, $duration4);
		}

		.listTile--family.js-showMenu & {
			@include fadeIn();
		}

		.listTile--product & {
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
			left: 0;
			padding: 0 ($base * 1.5);
			// pointer-events: none;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.listTile--product:hover & {
			@include fadeIn();
		}
	}


	&__menuItem {
		display: block;
		position: relative;
		z-index: 1;

		&:not(:first-child) {
			margin-top: -1px;
		}

		@include onHover {
			z-index: 10;
		}

		.listTile--product & {
			// @include font(tileMenu);
			// border-color: $colorProductsOverviewInactiveMenu;
			// border-style: solid;
			// border-width: 1px 0;
			// color: $colorBase;
			// display: block;
			// padding: ($base * 1.5) 0;
			// text-align: center;
		}
	}


	&__menuItems {
		display: none;

		@include bigEnoughLayout() {
			display: block;
		}
	}


	&__menuLink {
		@include ease(color border-color, $duration4);
		@include font(tileMenu);
		border-color: $colorProductsOverviewInactiveMenu;
		border-style: solid;
		border-width: 1px 0;
		color: $colorInverted;
		display: block;
		padding: ($base * 1.5) 0;
		text-align: center;
		text-decoration: none;

		.listTile__menuItems:hover &:not(:hover) {
			color: $colorProductsOverviewInactiveMenu;
		}

		@include onHover() {
			border-color: $colorInverted;
		}

		.listTile--product & {
			color: $colorBase;

			@include onHover() {
				border-color: $colorBase;
			}
		}
	}


	&__menuToggler {
		border: 0;
		background-color: transparent;
		bottom: 0;
		display: none;
		font-size: 0;
		left: 0;
		line-height: 0;
		position: absolute;
		text-indent: -1000px;
		top: 0;
		width: 100%;
		z-index: 2;

		@include bigEnoughLayout() {
			display: block;
		}
	}


	&__pageLink {
		bottom: 0;
		display: block;
		font-size: 0;
		left: 0;
		line-height: 0;
		position: absolute;
		right: 0;
		text-indent: -1000px;
		top: 0;
		z-index: 2;

		@include bigEnoughLayout() {
			display: none;
		}
	}


	&__title {
		@include font(tileProductName);
		padding-top: $base;
		position: relative;
		text-align: center;
		z-index: 3;

		.listTile--family & {
			@include font(tileFamilyName);
			pointer-events: none;
			text-transform: uppercase;
		}

		.filterableList--productsOverview.js-ready .listTile--family & {
			@include ease(font-size line-height letter-spacing, $duration4);
		}

		.listTile--family.js-showMenu & {
			@include font(tileFamilyNameReduced);
		}
	}
}
