.personDetails {
	@include font(category);
	display: grid;
	grid-template-columns: max-content 1fr;
	column-gap: $base2;
	row-gap: $base;

	.preview--person & {
		@include font(base);
	}


	.preview & {
		@include font(base);
		column-gap: $base2;
	}



	&__name {
		margin: 0;
		text-indent: 0;


		.preview &::after {
			content: ':';
		}
	}


	&__value {
		margin: 0;
		text-indent: 0;
	}
}
